@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,800,900');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

html,body
{
   font-family: 'Raleway', sans-serif;
   overflow-x: hidden; 
}

h1, h3, h5{
   color: #4e88b6;
}
p {
   color: #4d5961;
}

h2{
    color: #4e88b6;
    font-weight: 600;
    font-size: 40px;

    span{
      font-weight: 700;
    }
   }

.bg-image-full {
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

 .navbar.transparent.navbar-inverse .navbar-inner {
    border-width: 0px;
    -webkit-box-shadow: 0px 0px;
    box-shadow: 0px 0px;
    background-color: rgba(0,0,0,0.0);
    background-image: -webkit-gradient(linear, 50.00% 0.00%, 50.00% 100.00%, color-stop( 0% , rgba(0,0,0,0.00)),color-stop( 100% , rgba(0,0,0,0.00)));
    background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
    background-image: linear-gradient(180deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
}

.navbar.color-nav {
  background-color: #4e88b6;
  transition: background-color 0.9s ease 0s;
}

@media (min-width: 576px){
.modal-dialog {
    max-width: 900px;
    margin: 1.75rem auto;
}
}

.hero-body {
   background-color: #4e88b6;
   background: linear-gradient(rgba(89, 27, 124, 0), rgba(89, 27, 124, 0)), url('../img/bg-header.jpg') no-repeat center center;
   background-size: cover;
   height: 120vh;

   hr{
      width: 150px;
      color: #fff;
      margin: 50px 0 0 0;
      border-top: 4px solid #ffffff;
   }
   
   .heading {
      color: white;
      text-align: center;
      padding-top: 200px;
   }

   h1{
    color: #fff;
    font-weight: 300;
    font-size: 40px;
    margin-top: 15px;
    line-height: 60px;
    
    span{
      font-size: 50px;
      font-weight: 900;
      text-transform: uppercase;
    }
   }

   p {
      color:#fff;
      font-size: 25px;
      
       .btn {
         background-color: transparent;
         color: white;
         border: 1px solid white;
         padding: 10px;
         font-size: 1.2em;
             
          &:hover {
             color: rgb(126, 187, 211);
             border-color: rgb(126, 187, 211);
          }
      }
   }
}

.p-beneficios{
   margin-top: 10px;
   margin-bottom: 25px;
   font-size: 1rem!important;
   font-weight: 500!important;
   color: #8b969e;
}

.frame-video{
   width:100%;
   max-width:560px;
}

#call-section {
   background-color: #0089ad;
   background: linear-gradient(rgba(89, 27, 124, 0), rgba(89, 27, 124, 0)), url(../img/bg-call.jpg);
   margin-top: -56px;
   background-position: center top;
   background-size: 100% auto;
   height: 81vh;
   padding: 17% 0;
   background-repeat: no-repeat;

   hr{
      width: 150px;
      color: #fff;
      margin: 0 0 10px 0;
      border-top: 4px solid #ffffff;
   }

   p{
   color: #fff;
   font-size: 25px;
   font-weight: 100;
   }
   
   h2{ 
      color: #fff;
      font-weight: 300;
   }

    .btn-call{
      margin-top: 35px;
    }
}

#call-section-2 {
    background-color: #1b327c;
    background: linear-gradient(rgba(89, 27, 124, 0.7), rgba(89, 27, 124, 0.7)), url(../img/bg-call.jpg) no-repeat center center;
    background-size: cover;
    margin-top: 50px;
    padding: 50px;

   label{
      color: #fff;
      font-size: 14px;
   }

   h2{
      margin-bottom: 25px;
      color: #fff;
      font-weight: 300;
   }
   
   p{ color: #fff; }

   h5{ color: #fff; }

   .btn-call{
   margin-top: 40px;
   }

   img{
      margin-top: 50px;
   }
}

#call-section-3 {
    background-color: #1b357c;
    background: linear-gradient(rgba(89, 27, 124, 0.7), rgba(89, 27, 124, 0.7)), url(../img/bg-call.jpg) no-repeat center center;
    background-size: cover;
    margin-top: 50px;
    padding: 50px;

   label{
      color: #fff;
      font-size: 14px;
   }

   h2{
      margin-bottom: 25px;
      color: #fff;
      font-weight: 300;
   }
   
   p{ color: #fff; }

   h5{ color: #fff; }

   .btn-call{
   margin-top: 40px;
   }

   img{
      margin-top: 50px;
   }
}

.all-content{
    background-color: rgba(255, 255, 255, 0);
    border-radius: 10px;
    padding: 50px 0;
}

#sobre{
   padding: 0 50px 100px 50px;

   hr{
   margin-top: 1rem;
   margin-bottom: 1rem;
   border: 0;
   border-top: 3px solid rgb(78, 136, 182);
   width: 150px;
   }
}

#video{
   padding: 100px 50px 25px 50px;
}

#sistema{
   padding: 0 0 0 50px;
}

#testemulhos{
   padding: 100px 50px 25px 50px; 
}

.logo-cliente{
   margin-top: 100px; 
}

.logo-app{
   max-width: 35px;
   width: 100%;
}

.navbar-brand {
   font-size: 15px!important;
}

.img-app{
   max-width: 800px;
   width: 100%;
}

.vcenter {
   max-width: 1140px;
   width: 95%;
   margin: 0;
   position: absolute;
   top: 50%;
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
}

.btn-call{
   color: #fff;
   border: 3px solid #ffffff;
   border-radius: 100px;
   padding: 13px 35px 12px 35px;
   letter-spacing: .7px;
   background-color: rgba(0, 0, 0, 0);
   font-weight: 700;


   &:hover{
      background-color: #4e88b6;
      color:#fff;
   }

   &:active{
      box-shadow: 0px 0px #fff;
   }

   &:focus{
      box-shadow: 0px 0px #fff;
   }
}

.navbar-dark .navbar-nav .nav-link {
   color: #fff;
   letter-spacing: 0.3px;
   font-size: 14px;
   padding: 12px;
}
.navbar-dark .navbar-nav .btn-call {
   margin-left: 10px;
   padding: 8px 25px!important;

   &:hover{
      color: #009ca3;
   }
}


.beneficios .col-primeira, .beneficios .col-segunda {
   margin-top: 80px;
}

.beneficios h3{
   margin-bottom: 0rem;
   color: #4e88b6;
   font-size: 20px;
}

.beneficios p{
   margin-top: 0px;
   margin-bottom: 25px;
   font-size: 1rem;
   font-weight: 300;
}

.form-control {
    margin-top: 20px;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 1.4rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: #4e88b6;
    border-color: #4e88b6;

    &:hover {
    background-color: hsl(207, 55%, 35%);
    border-color: hsl(207, 55%, 35%);      
    }
}

.card {
    border: 1px solid rgba(0,0,0,0);
}


footer{
   margin-top: 50px;
   background: url(../img/img-footer.jpg) no-repeat top center;
   background-size: cover;
   padding-top: 100px;
   padding-bottom: 100px;

   p{
      color: #fff;
   }

   a{
      text-decoration: none;
      color: rgb(230, 167, 22);

      &:hover{
      text-decoration: none;
      color: rgb(175, 131, 26);

      }
   }

   ul{
   list-style-type: none;
   text-align: center;
   }

   li{
   float: left;
   }

   li a{
   color: #14367f;
   display: block;
   text-align: center;
   text-decoration: none;
   }

   li a:hover{
      color: #009da5;

   }

   .list-inline{
   display: flex;
   justify-content: center;
   }

   img{
      width: 250px;
      margin-bottom: 15px;
   }

   .footer-p{
      position: absolute;
      bottom: 20px;
   }

   .list-inline .link-footer{
    color: #fff;
    font-size: 18px;
    border: 2px solid #fff;
    padding: 10px 30px;
    border-radius: 100px;
    margin-right: 10px;
   }

   .list-inline i{
    color: #fff;
    font-size: 18px;
    border: 2px solid #fff;
    padding: 15px 16px;
    border-radius: 100px;
    margin-right: 10px;
   }
}

.carousel {
	margin: 50px auto;
	padding: 0 70px;
}
.carousel .item {
	color: #999;
	font-size: 14px;
    text-align: center;
	overflow: hidden;
    min-height: 290px;
}
.carousel .item .img-box {
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .img-box img {
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 50%;
}
.carousel .testimonial {
	padding: 30px 0 10px;
}
.carousel .overview {	
	font-style: italic;
}
.carousel .overview b {
	text-transform: uppercase;
	color: #4e88b6;
}
.carousel .carousel-control {
	width: 40px;
    height: 40px;
    margin-top: -20px;
    top: 50%;
	background: none;
}
.carousel-control i {
    font-size: 30px;
	line-height: 42px;
    position: absolute;
    display: inline-block;
	color: rgba(0, 0, 0, 0.8);
    text-shadow: 0 3px 3px #e6e6e6, 0 0 0 #000;
}
.carousel .carousel-indicators {
	bottom: -40px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 10px;
	height: 10px;
	margin: 1px 3px;
	border-radius: 50%;
}
.carousel-indicators li {
   border-top: 0px solid transparent;
   border-bottom: 0px solid transparent; 
	background: #999;
	border-color: transparent;
	box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
}
.carousel-indicators li.active {	
	background: #555;		
	box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
}


.navbar-dark .navbar-toggler {
    color: rgba(255,255,255,1);
    border-color: rgba(255,255,255,0);
}

button:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
} 

.beneficios .col-primeira{
   text-align: right;
}


// Versão Mobile

@media only screen and (max-width: 600px) {
.hero-body h1 span {
    font-size: 40px;
}

.vcenter {
   top: 35%;
}

.color-nav-mob {
   background-color: rgb(0, 148, 196) !important;
   padding: 10px 20px 20px;
   border-radius: 20px 0px 20px 0px;
}

.all-content {
    border-radius: 0;
    box-shadow: 0px 0px 0px 0px rgba(94, 82, 99, 0);
    padding: 50px 0;
}

.hero-body {
    height: 170vh;
    text-align: center;
}

.img-app {
   margin-top: 40px;
}

.vcenter {
   width: 95%;
   -ms-transform: translateY(-20%);
   transform: translateY(-20%);
}

#sobre {
   padding: 150px 15px 100px 15px;

   hr{
   margin-top: 1rem;
   margin-bottom: 1rem;
   border: 0;
   border-top: 3px solid rgb(78, 136, 182);
   width: 150px;
   }
}

#video{
   padding: 100px 15px 25px 15px;
}

#sistema{
   padding: 0 0 0 15px;
}

#testemulhos{
   padding: 100px 15px 25px 15px; 
}

.beneficios{ 
   text-align: center;
}

.beneficios .col-primeira{
   text-align: center;
}

#call-section {
   padding: 180px 15px 50px 15px;
   text-align: center;
   background-size: 450% auto;
   height: 140vh;

   hr{
      display:none;
   }
}

.logo-cliente {
    margin-top: 100px;
    text-align: center;
}

.logo-cliente .col-md-2 {
   margin-top: 40px;   
}

.navbar-dark .navbar-brand {
    color: #fff;
    font-size: 15px;
}

#call-section-2 {
   padding: 50px 15px; 
}

#call-section-3 {
   padding: 50px 15px; 
}

}